// extracted by mini-css-extract-plugin
export var article = "ArticlePage-module--article--fd93a";
export var article_author = "ArticlePage-module--article_author--88466";
export var article_content = "ArticlePage-module--article_content--200cc";
export var article_date = "ArticlePage-module--article_date--2623d";
export var article_header = "ArticlePage-module--article_header--0e3e8";
export var article_headerDetails = "ArticlePage-module--article_headerDetails--8f06b";
export var article_headline = "ArticlePage-module--article_headline--6fbe3";
export var article_image = "ArticlePage-module--article_image--b0f20";
export var article_imagePlaceholder = "ArticlePage-module--article_imagePlaceholder--76881";
export var article_narrowCol = "ArticlePage-module--article_narrowCol--196c4";
export var article_tag = "ArticlePage-module--article_tag--2373b";
export var backBtn_arrow = "ArticlePage-module--backBtn_arrow--8951a";
export var headerBtn = "ArticlePage-module--headerBtn--29257";
export var headerBtn_Wrap = "ArticlePage-module--headerBtn_Wrap--19044";
export var news_navBar = "ArticlePage-module--news_navBar--7d3cd";