import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { Icon } from '@iconify/react'

import GeneralPageLayout from './../components/Layouts/GeneralPage'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import Seo from './../components/Header/SEO/Seo'

import '@wordpress/block-library/build-style/style.css'
import '@wordpress/block-library/build-style/theme.css'

import * as styles from './../styles/ArticlePage.module.scss'

export default function articleTemplate({ data: { post }, location }) {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Precise',
    title2: 'News',
    breadcrumbs: [''],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  }
  const displayImg = getImage(featuredImage.data)

  const mainCategories = {
    'Giving Back': 'Giving Back',
    Corporate: 'Corporate',
    Veterans: 'Veterans',
    'Press Release': 'Press Release',
    '#PeopleofPrecise': '#PeopleofPrecise',
  }
  let category = post.categories?.nodes.filter((f) => !mainCategories[f.name])

  let similarArticles = post.categories?.nodes.filter(
    (f) => mainCategories[f.name]
  )
  if (similarArticles.length > 0) {
    similarArticles =
      similarArticles[0].name === '#PeopleofPrecise'
        ? 'people-of-precise'
        : similarArticles[0].name.replace(/\s+/g, '-').toLowerCase()
  } else {
    similarArticles = null
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <Seo title={post.title} description={post.excerpt} />
      <div className="content">
        <SectionContainer id="" color="white" type="contained">
          {post ? (
            <article
              className={`blog-post ${styles.article}`}
              itemScope
              itemType="http://schema.org/Article"
            >
              <section
                id="top"
                className={`inlineChildren_lgMobile alignChildrenCenter ${styles.news_navBar} ${styles.article_narrowCol}`}
              >
                <div className={styles.headerBtn_Wrap}>
                  <Link
                    className={styles.headerBtn}
                    to={`${
                      location.state?.prevPath
                        ? `${location.state.prevPath}#${post.id}`
                        : '/news'
                    }`}
                  >
                    <Icon
                      className={`${styles.backBtn_arrow}`}
                      icon="akar-icons:arrow-left"
                      inline={true}
                    />
                    Back
                  </Link>
                  {similarArticles && (
                    <Link
                      className={styles.headerBtn}
                      to={`/news/${similarArticles
                        .replace(/\s+/g, '-')
                        .toLowerCase()}/
                  `}
                    >
                      View Related Articles
                    </Link>
                  )}
                </div>
              </section>

              <header className={styles.article_header}>
                <div className={styles.article_narrowCol}>
                  <p className={styles.article_date}>{post.date}</p>
                  <h1 itemProp="headline" className={styles.article_headline}>
                    {parse(post.title)}
                  </h1>
                  <div className={styles.article_headerDetails}>
                    {category[0]?.name ? (
                      <div className={styles.article_tag}>
                        {category[0]?.name}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <p className={styles.article_author}>
                      By {post.author.node.name}
                    </p>
                  </div>
                </div>

                {/* if we have a featured image for this post let's display it */}
                <div className={styles.article_image}>
                  {featuredImage?.data ? (
                    <GatsbyImage
                      image={displayImg}
                      alt={`${featuredImage.alt}`}
                      style={{ marginBottom: 50 }}
                    />
                  ) : (
                    <div className={styles.article_imagePlaceholder}></div>
                  )}
                </div>
              </header>

              {!!post.content && (
                <section
                  className={`${styles.article_content}`}
                  itemProp="articleBody"
                >
                  {parse(post.content)}
                </section>
              )}

              <hr />

              <footer>{/* <Bio /> */}</footer>
            </article>
          ) : (
            <div>Loading...</div>
          )}
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export const pageQuery = graphql`
  query ArticlePostById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                backgroundColor: "#DADEDF"
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
